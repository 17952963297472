* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  color: white;
  font-family: sans-serif;
}

html {
  font-size: 62.5%;
}

@media only screen and (max-width: 1260px) {
  html {
    font-size: 50%;
  }
}
