@import "../../../assets/scss/colors";

.UserList {
  padding: 3.2rem;
  border-bottom: 2px solid $colorAccent;

  &__heading {
    color: $colorTextDark;
    font-size: 3.2rem;
    margin-bottom: 3.2rem;
    text-align: center;
  }

  &__empty {
    color: $colorTextDark;
    font-size: 2.4rem;
    text-align: center;
  }
}

.Table {
  border: 2px solid $colorAccent;
  border-collapse: collapse;
  margin: 0 auto;

  & th,
  td {
    border: 2px solid $colorAccent;
    padding: 0.8rem 5.6rem;
  }

  & td {
    text-align: center;
    color: $colorTextDark;
    font-size: 1.6rem;
  }

  &__heading {
    color: $colorTextDark;
    font-size: 1.8rem;
  }

  &__BorderNone {
    width: 1%;
    border: none !important;
    text-align: start !important;
    position: relative;

    &:after {
      content: "\2190";
      position: absolute;
      top: 50%;
      left: 0;
      color: $colorAccent;
      transform: translate(2.4rem, -50%);
    }
  }

  &__Delete {
    &:after {
      content: "\2190";
      position: absolute;
      top: 50%;
      left: 0;
      color: $colorError !important;
      transform: translate(2.4rem, -50%);
    }
  }
}
