@import "../../assets/scss/colors";

.Button {
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 400;
  padding: 1.2rem 3.6rem;
  outline: none;
  border: none;
  display: inline-block;
  transition: 250ms;

  &--light {
    background-color: $colorAccent;
    color: $colorTextLight;
  }

  &--dark {
    background-color: $colorTextLight;
    color: $colorAccent;
  }

  &--error {
    background-color: $colorError;
    color: $colorTextLight;
  }

  &--text-sm {
    font-size: 1.2rem;
  }

  &--centered {
    text-align: center;
  }

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.99);
  }
}
