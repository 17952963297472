@import "../../../assets/scss/colors";

.ImagePreviews {
  padding: 3.2rem;

  &__heading {
    color: $colorTextDark;
    font-size: 3.2rem;
    margin-bottom: 3.2rem;
    text-align: center;
    align-items: center;
  }

  &__images-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3.2rem;
  }
}
