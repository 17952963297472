@import "../../assets/scss/colors";

.Login {
  min-height: 100dvh;
  background-color: $colorBackground;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__header {
    color: $colorTextDark;
    font-size: 6.4rem;
  }
}
