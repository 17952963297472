@import "../../assets/scss/colors";

.Header {
  padding: 3.2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 2.4rem;
  background-color: $colorAccent;
  border-bottom: 2px solid $colorAccent;

  &__heading {
    font-size: 3.2rem;
    color: $colorTextLight;
  }

  &__user-info {
    color: $colorTextLight;
    font-size: 1.6rem;
    font-weight: 600;

    &-container {
      display: flex;
      flex-wrap: wrap;
      gap: 4.8rem;
    }
  }
}
