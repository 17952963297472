@import "../../../assets/scss/colors";

.RegisterUser {
  padding: 3.2rem;
  border-bottom: 2px solid $colorAccent;

  &__heading {
    color: $colorTextDark;
    font-size: 3.2rem;
    margin-bottom: 3.2rem;
    text-align: center;
    align-items: center;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    & > *:last-child {
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      margin-top: 4.8rem;
    }
  }

  &__label {
    color: $colorTextDark;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    margin-right: 1.2rem;
    position: relative;
  }

  &__input {
    background-color: transparent;
    color: $colorTextDark;
    outline: none;
    border: 2px solid $colorAccent;
    font-size: 1.6rem;
    padding: 0.4rem;
    transition: 200ms;

    &:not(:last-of-type) {
      margin-right: 4.8rem;
    }

    &:focus {
      box-shadow: 1px 1px $colorAccent;
    }
  }

  &__ErrorLog {
    position: absolute;
    top: 3.2rem;
    left: 0;
    color: $colorError;
    font-size: 1.6rem;
    font-weight: 600;
    width: 250px;
  }
}
