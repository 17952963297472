@import "../../../assets/scss/colors";

.LoginForm {
  position: relative;
  margin-top: 8.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__label {
    color: $colorTextDark;
    font-size: 2.4rem;
    margin-bottom: 1.2rem;
  }

  &__input {
    background-color: transparent;
    color: $colorTextDark;
    outline: none;
    border: 2px solid $colorAccent;
    font-size: 1.6rem;
    padding: 0.4rem;
    transition: 200ms;

    &:not(:last-of-type) {
      margin-bottom: 3.6rem;
    }

    &:focus {
      box-shadow: 1px 1px $colorAccent;
    }
  }

  & button {
    margin-top: 8.4rem;
  }

  &__ButtonsContainer {
    display: flex;
    gap: 6.4rem;
  }

  &__ErrorLog {
    color: $colorError;
    position: absolute;
    font-size: 1.6rem;
    font-weight: 600;
    top: -2.4rem;
  }
}
