@import "../../assets/scss/colors";

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba($colorPrimary, 0.5);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.Modal {
  background-color: $colorBackground;
  border: 2px solid $colorAccent;
  max-width: 48rem;
  aspect-ratio: 1;
  padding: 3.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 3;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    z-index: 3;
  }

  &__heading {
    color: $colorTextDark;
    font-size: 3.2rem;
    text-align: center;
  }

  &__infoContainer {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  &__info {
    color: $colorTextDark;
    font-size: 2.4rem;
  }

  &__ButtonContainer {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}
