@import "../../../../assets/scss/colors";

.CountedImage {
  padding: 3.2rem;
  border: 2px solid $colorAccent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;

  &__empty {
    font-size: 1.8rem;
    color: $colorGrey;
  }

  &__desc {
    color: $colorTextDark;
    font-size: 2.4rem;
    font-weight: 600;
    text-align: center;
  }
}
